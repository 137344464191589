export class RoleRightModel {
    RoleId: number;
    UserRolesRightsList: any;

    constructor() {
        this.RoleId = 0;
        this.UserRolesRightsList = [];
    }
}
export class UserRightModel {
    UserMasterId: number;
    UserRightsList: any;

    constructor() {
        this.UserMasterId = 0;
        this.UserRightsList = [];
    }
}
export class UserModel {
    FirstName: string
    MiddleName: string
    LastName: string
    UserEmail: string
    ContactNo: string
    GenderId: number;
    UserName: string;
    RoleId: number;
    DepartmentId: number;
    IsActive: boolean;
    Password: string;
    ConfirmPassword: string;
    UserMasterId: number
    UserType: number

    constructor() {
        this.FirstName = '';
        this.MiddleName = '';
        this.LastName = '';
        this.UserEmail = '';
        this.ContactNo = '';
        this.GenderId = 0;
        this.UserName = '';
        this.RoleId = 0;
        this.DepartmentId = 0;
        this.IsActive = false;
        this.Password = '';
        this.ConfirmPassword = '';
        this.UserMasterId = 0;
        this.UserType = 3;
    }
}
export class DepartmentModel {
    DepartmentId: number;
    DepartmentName: string;
    IsActive: boolean;
    UserId: number;
    UserType: number

    constructor() {
        this.DepartmentId = 0;
        this.DepartmentName = '';
        this.IsActive = false;
        this.UserId = 0;
        this.UserType = 3
    }
}
export class RoleModel {
    RoleId: number;
    RoleName: string;
    IsActive: boolean;
    UserId: number;
    UserType: number

    constructor() {
        this.RoleId = 0;
        this.RoleName = '';
        this.IsActive = false;
        this.UserId = 0;
        this.UserType = 3
    }
}