import { useEffect, useState } from "react";
import { verifytoken } from "../../services/ApiCallingUserServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar, Doughnut, Line } from 'react-chartjs-2'
import {
   Chart as ChartJS,
   LineElement,
   BarElement,
   CategoryScale, // x axis
   LinearScale,   // y axis
   PointElement,
   ArcElement,
   Legend,
   Tooltip,
} from 'chart.js'
import { ClearLocalStorage, GetLocalStorage } from "../../_commonfunction/Session";

const Dashboard = () => {
   ChartJS.register(
      LineElement,
      CategoryScale,
      LinearScale,
      PointElement,
      ArcElement,
      BarElement,
      Legend,
      Tooltip,
   )
   const dispatch = useDispatch()
   const navigate = useNavigate();
   const [username, setUsername] = useState('');
   const user = useSelector((store: any) => store.userstatus.tba);
   // let tokenm=GetLocalStorage();

   // useEffect(() => {
   //      if(!tokenm && !tokenm?.userName && !tokenm?.token){
   //      //if (!Window.$name && !Window.$userToken) {


   //       ClearLocalStorage(); 
   //       dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
   //       // alert('Your Session Expired. Please Login Again!');
   //       // navigate('/');
   //   }
   //   else{
   //    async function verifyTokenValue () {
   //       let statusoftoken = await verifytoken();
   //       if(!statusoftoken){
   //          ClearLocalStorage();
   //          dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
   //          // alert('Your Session Expired. Please Login Again!');
   //          // navigate('/');
   //       }
   //   }
   //   verifyTokenValue();
   //   }

   // },[]);
   const data = {
      labels: ['Jan', 'Fab', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
         {
            label: 'Dataset 1',
            data: [220, 170, 270, 200, 190, 210, 260, 310, 360, 400, 340, 350],
            borderColor: 'rgba(35,162,205,255)',
            backgroundColor: 'rgba(35,162,205,255)',
            tension: .5
         },
         {
            label: 'Dataset 2',
            data: [350, 390, 400, 380, 340, 310, 300, 320, 400, 300, 250, 210],
            borderColor: 'rgba(255,186,74,255)',
            backgroundColor: 'rgba(255,186,74,255)',
            tension: .5
         },
      ]
   }
   const data1 = {
      labels: ['Jan', 'Fab', 'Mar', 'Apr',],
      datasets: [{
         label: 'My First Dataset',
         data: [65, 59, 80, 81],
         backgroundColor: [
            'rgba(255, 99, 132, 0.2)'
         ],
         borderColor: [
            'rgb(255, 99, 132)'
         ],
         borderWidth: 1,
         borderRadius: Number.MAX_VALUE,
         borderSkipped: false,
      }]
   };
   const DoughnutChart = {
      labels: ['Red', 'Blue'],
      datasets: [
         {
            label: '# of Votes',
            data: [50, 50],
            backgroundColor: [
               'rgba(239,91,91,255)',
               'rgba(210,211,213,255)',
            ],
            borderColor: [
               'rgba(239,91,91,255)',
               'rgba(210,211,213,255)',
            ],
            borderWidth: 1,
            hoverOffset: 2,
            spacing: 1
         },
      ],
   };

   useEffect(() => {
      const tokenm = GetLocalStorage();
      setUsername((tokenm !== null || tokenm !== undefined || tokenm !== '') ? tokenm.userName : '');

      async function verifyTokenValue() {
         let statusoftoken = await verifytoken();
         if (!statusoftoken) {

            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
         }

      }
      verifyTokenValue();

   }, []);



   return (
      <>
         <div className="card my-3">
            <div className="card-header">
               <div className="container-fluid">
                  <h3 className="mb-0">Dashboard</h3>
               </div>
            </div>
            <div className="card-body">
               <div className="row">
                  <div className="col-xl-8 col-md-12">
                     <div className="row">
                        <div className="col-xl-4 col-md-6">
                           <div className="card bg-warning text-white mb-4">
                              <div className="card-body">
                                 <div className="row">
                                    <div className="col-8">
                                       <div className="inner">
                                          <h3>53%</h3>
                                          <p className="text-white">Lorem</p>
                                       </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-center align-items-center">
                                       <div className="icon">
                                          <i className="fa-solid fa-3x fa-chart-simple icons_color"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="card-footer d-flex align-items-center justify-content-between">
                                 <a className="small text-white stretched-link">View Details</a>
                                 <div className="small text-white"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                           <div className="card bg-success text-white mb-4">
                              <div className="card-body">
                                 <div className="row">
                                    <div className="col-8">
                                       <div className="inner">
                                          <h3>44</h3>
                                          <p className="text-white">Lorem</p>
                                       </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-center align-items-center">
                                       <div className="icon">
                                          <i className="fa-solid fa-3x fa-user-plus icons_color"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="card-footer d-flex align-items-center justify-content-between">
                                 <a className="small text-white stretched-link">View Details</a>
                                 <div className="small text-white"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                           <div className="card bg-danger text-white mb-4">
                              <div className="card-body">
                                 <div className="row">
                                    <div className="col-8">
                                       <div className="inner">
                                          <h3>65</h3>
                                          <p className="text-white">Lorem</p>
                                       </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-center align-items-center">
                                       <div className="icon">
                                          <i className="fa-solid fa-3x fa-chart-pie icons_color"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="card-footer d-flex align-items-center justify-content-between">
                                 <a className="small text-white stretched-link">View Details</a>
                                 <div className="small text-white"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-12">
                           <div className="card mb-4">
                              <div className="card-header">
                                 <i className="fa fa-area-chart me-1" aria-hidden="true"></i>
                                 Area Chart
                              </div>
                              <div className="card-body"><Line data={data}></Line></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-md-12">
                     <div className="row">
                        <div className="col-xl-12">
                           <div className="card mb-4">
                              <div className="card-header">
                                 <i className="fa fa-bar-chart me-1" aria-hidden="true"></i>
                                 Bar Chart
                              </div>
                              <div className="card-body"><Bar data={data1}></Bar></div>
                           </div>
                        </div>
                        <div className="col-xl-12">
                           <div className="card mb-4">
                              <div className="card-header">
                                 <i className="fa fa-bar-chart me-1" aria-hidden="true"></i>
                                 Bar Chart
                              </div>
                              <div className="card-body position-relative"><Doughnut data={DoughnutChart}></Doughnut><span className="d-flex justify-content-center align-items-center position-absolute">50%</span></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div >
      </>
   )
}

export default Dashboard;