import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getOtpPostReq } from "../../services/ApiCallingUserServices";
import { useDispatch, useStore } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { useNavigate } from "react-router";

interface OtpModelProps {
  show: boolean;
  setShow: Function;
  userDetails: string;
  sendOtpOfFOrgotPassword: any;
  forgetData: any;
}

const OtpModel: React.FC<OtpModelProps> = ({
  show,
  setShow,
  userDetails,
  sendOtpOfFOrgotPassword,
  forgetData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState<any>(["", "", "", ""]);
  const [otpinputclasstoggle, setOtpIputClassToggle] = useState(false);
  const [resenotp, setResenotp] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [errors, setErrors] = useState("");
  const [resendbtndisabled, setResendbtndisabled] = useState(false);

  const inputRefs = useRef<any>([null, null, null, null]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        setResenotp(true);
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(30);
          setMinutes(minutes - 1);
        }
        setResendbtndisabled(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    
    if (show) {
      setSeconds(30);
      setResenotp(false);
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setOtp((pre: any) => ["", "", "", ""]);
    setOtpIputClassToggle(false);
    setSeconds(30);
    setErrors("");
    dispatch(loaderActions.end());
  };

  const handleKeyPress = (index: any, e: any) => {
    if (otp[index].length === 0) {
      if (e.keyCode === 8 && index >= 1) {
        inputRefs.current[index - 1].focus();
      }
    }
    if (e.keyCode === 39 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
    if (e.keyCode === 37 && index >= 1) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOnChange = (index: any, value: any, e: any) => {
    if (Number(value) || value === "" || value == 0) {
      setOtp((prev: any) => {
        const newOTP = [...prev];
        newOTP[index] = value.trim();
        if (value.length === 1 && index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].focus();
        }

        return newOTP;
      });
      setErrors("");
      setOtpIputClassToggle(false);
    }
  };

  const handleotpverify = async (e: any) => {
    e.preventDefault()
    dispatch(loaderActions.start());

    if (otp.join("").trim().length < 4) {
      setOtpIputClassToggle(true);
      setErrors("Wrong OTP");
      return;
    }

    if (resenotp) {
      const expireOTP = await getOtpPostReq("/auth/v1/otpexpire", {
        UserEmail: userDetails,
        otp: otp.join("").trim(),
      });
      if (expireOTP.status) {
        setErrors("OTP Expired");
        setSeconds(0);
      }

    } else {
      const veridyOTP = await getOtpPostReq("/auth/v1/otpverify", {
        UserEmail: userDetails,
        otp: otp.join("").trim(),
      });
      if (veridyOTP.status) {
        handleClose();

        navigate("/resetpassword", {
          state: {
            userMasterID: forgetData[0].userMasterID,
          },
        });

      } else {
        setErrors(veridyOTP.message);
        setOtpIputClassToggle(true);
      }
    }

    dispatch(loaderActions.end());
  };

  const handleResendOtp = (e: any) => {
    e.preventDefault()
    //resend otp function
    sendOtpOfFOrgotPassword(e);

    setSeconds(30);
    setResenotp(false);
    setResendbtndisabled(true);
    setOtpIputClassToggle(false)
    setOtp((pre: any) => ["", "", "", ""]);
    setErrors("");
  }

  return (
    <>
      <div className={` ${show ? "d-block " : "d-none"}  modal  `}>
        <div className="modal-dialog modal-dialog-centered modal-sm ">
          <div className="modal-content">
            <div className="row mx-1  mt-2 ">
              <div className="col-10">
                <h5 className="modal-title text-start" id="">
                  Verify OTP
                </h5>
              </div>

              <div className="col-2 text-end ">
                <button
                  type="button"
                  id="closemodal"
                  className="btn fw-bold otp_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  X
                </button>
              </div>
            </div>

            <div className="row mx-1 mt-1">
              <p className="text-muted text-start otp_ptext">
                Please enter the 4 digit code received in your registered
                Email/mobile to reset your password.
              </p>
            </div>

            {errors && (
              <div className="text-danger text-start mx-3 ">{errors}</div>
            )}

            <div className="d-flex px-2 my-2">
              {otp.length === 4 &&
                otp?.map((val: any, index: any) => (
                  <div className="mx-2">
                    <input
                      className={`form-control py-2 text-center fs-5 
                                             ${otpinputclasstoggle
                          ? "blankinput_border"
                          : ""
                        }`}
                      type="text"
                      id={index}
                      name={index}
                      key={index}
                      onKeyDown={(e) => handleKeyPress(index, e)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      value={val}
                      onChange={(e) => handleOnChange(index, e.target.value, e)}
                      maxLength={1}
                    />
                  </div>
                ))}
            </div>

            <div className="col-12 px-3 ">
              {seconds > 0 || minutes > 0 ? (
                <p className="text-muted">
                  Resend OTP in {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}s
                </p>
              ) : (
                <>
                  <button
                    className={
                      ` ${resenotp}`
                        ? "d-block border-0 bg-white small forgotpsw text-primary resend_button btn shadow-none resendbtn"
                        : "d-none"
                    }
                    disabled={resendbtndisabled}
                    onClick={(e: any) => handleResendOtp(e)}
                  >
                    Resend OTP
                  </button>
                </>
              )}
            </div>

            <div className="row">
              <div className="mx-auto col-lg-6 col-md-6 col-6 mt-2 mb-3">
                <button
                  className="btn btn_verify form-control"
                  onClick={(e) => {
                    handleotpverify(e);
                  }}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpModel;
