import { isDisabled } from "@testing-library/user-event/dist/utils";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useContext } from "react";
import "../../src/assets/css/Resetpassword.css";
import { useNavigate } from "react-router-dom";

export function ConfirmRePasswordModal(props: any) {
  const handleKeyDown = (e: any) => {
    if (e.code === "Escape") {
      e.preventDefault();
      props.yesconfirm();
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onKeyDown={handleKeyDown}
      backdrop="static" // prevents clicks outside the modal from closing it
    >
      <Modal.Header className=" col-12 modelheader bg-primary header_closebtn">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="col-9 header_text mx-4 ps-3 h5"
        >
          Message
        </Modal.Title>
        <Button
          id="btn_verify1"
          variant="primary"
          className="col-1 custom_cancel_button"
          onClick={props.yesconfirm}
          // autoFocus={false}
        >
          ✖
        </Button>
      </Modal.Header>
      <Modal.Body className=" mt-3 modalbody">
        <p className="text-center fw-bold my-1">Password Reset Successfully</p>
      </Modal.Body>
      <Modal.Footer className="modalfooter">
        <div className="mx-auto">
          <Button
            id="btn_verify"
            variant="primary"
            className="mx-2 px-3"
            onClick={props.yesconfirm}
            autoFocus={true} //focus on button only
          >
            Ok
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
