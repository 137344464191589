import { userConstatnt } from "../_constants/user.constant";


const initialValues = {
    tba:null
}

export function userstatus(state = initialValues, actions:any) {
    switch (actions.type) {
        case userConstatnt.USERDATA:
            return { tba: actions.tba};
        default:
            return state;
    }
}
export function menuid(state = 0, actions:any) {
    switch (actions.type) {
        case userConstatnt.MENUID:
            return { menuId: actions.menuId};
        default:
            return state;
    }
}