export class ResetpasswordModel {
  UserMasterID: number;
  Password: string;
  rePassword: string;
  UserType: number;
  constructor() {
    this.UserMasterID = 0;
    this.Password = "";
    this.rePassword = "";
    this.UserType = 3;
  }
}
