import React from "react";
import "../../assets/css/Resetpassword.css";
import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
// import { History, Update } from "history";
import LandingPageDesign from "./LandingPageDesign";
import { ResetpasswordModel } from "./Modals/ResetPasswordModel";
import { validation } from "../../_commonfunction/validation";
import { getOtpPostReq } from "../../services/ApiCallingUserServices";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ConfirmRePasswordModal } from "../../customControl/ConfirmRePasswordModal";

export default function ResetPassword() {
  const [modalShow, setModalShow] = useState(false);
  const [formPassword, setFormPassword] = useState(new ResetpasswordModel());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgot = useLocation();

  const [formError, setFormError] = useState({
    password: "",
    rePassword: "",
  });
  let errmessage = {
    password: "",
    rePassword: "",
  };

  useEffect(() => {
    dispatch(loaderActions.start());
    if (forgot?.state?.userMasterID > 0) {
      formPassword.UserMasterID = forgot.state.userMasterID;
    } else {
      navigate("/");
    }
    // formPassword.UserMasterID = 216;
    dispatch(loaderActions.end());
  }, []);

  const handlechange = (e: any) => {
    const { name } = e.target;

    //changes here as any
    let result: any = "";
    if (name === "Password") {
      result = validation.passWord(e.target.value);
      if (e.target.value && formPassword.rePassword === e.target.value) {
        document
          .getElementById("resetPasswordspan")
          ?.classList.remove("d-none");
      } else {
        document.getElementById("resetPasswordspan")?.classList.add("d-none");
      }
    } else {
      result = e.target.value;
      if (e.target.value && formPassword.Password === e.target.value) {
        document
          .getElementById("resetPasswordspan")
          ?.classList.remove("d-none");
      } else {
        document.getElementById("resetPasswordspan")?.classList.add("d-none");
      }
    }
    setFormPassword({ ...formPassword, [name]: result });
    setFormError({ ...formError, password: "", rePassword: "" });
  };

  const validateData = () => {
    let status = true;
    const passwordregex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[!@#$%^&*()_+=\[{\]};:<>|?,-]).{8,15}$/;

    if (!formPassword.Password) {
      errmessage.password =
        "Password must contain at least upper case, 1 number, 1 special character and minimum 8 character long";
      status = false;
    }
    if (!formPassword.rePassword) {
      errmessage.rePassword = " Kindly re-enter the password";
      status = false;
    }
    if (formPassword.Password && formPassword.Password?.length < 8) {
      errmessage.password =
        "Password must contain at least upper case, 1 number, 1 special character and minimum 8 character long";
      status = false;
    }
    if (formPassword.Password && !passwordregex.test(formPassword.Password)) {
      errmessage.password =
        "Password must contain at least upper case, 1 number, 1 special character and minimum 8 character long";
      status = false;
    }
    if (
      formPassword.rePassword &&
      formPassword.Password !== formPassword.rePassword
    ) {
      errmessage.rePassword = "Enter Password mismatch";
      status = false;
    }

    setFormError(errmessage);
    return status;
  };

  const handleResetPassword = (e: any) => {
    e.preventDefault();
    if (!validateData()) {
      return;
    } else {
      handlesubmit();
    }
  };
  const handlesubmit = async () => {
    const resetpassword = await getOtpPostReq(
      "/auth/v1/resetpassword",
      formPassword
    );

    if (resetpassword.status && resetpassword.data > 0) {
      setModalShow(true);
    } else {
      dispatch({ type: "TRYAGAIN", message: resetpassword.message });
    }
  };

  const handleConfirmation = (e: any) => {
    //setYesNoConfirm(e)
    if (e === true) {
      setFormPassword(new ResetpasswordModel());
      document.getElementById("resetPasswordspan")?.classList.add("d-none");
      // localStorage.removeItem("myData");
      navigate("/");
    }
    setModalShow(false);
  };
  
  useEffect(()=>{
    window.history.forward();
 },[])

  //prevents the page to go back from browser

  return (
    <LandingPageDesign>
      <div className="card-header bg-body border-0 d-flex mx-2 mt-lg-0 pt-lg-0 pt-5 mt-5 ">
        <div className="col-12">
          <div className="row ">
            {/* <div className="col-2 py-0 mt-2">
              <i
                className="col-1 fa fa-2x fa-arrow-left cursor_pointer"
                onClick={handleBack}
              ></i>
            </div> */}
            <div className="col-10 ">
              <h2 className="  text-start font-weight-light my-2 color_newblue fw-bold">
                Reset Password
              </h2>
              <p className=" otp_mess">OTP verified!!!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <form action="">
          <div className="d-flex">
            <div className="col-lg-12 col-md-12 col-12 my-0">
              <label htmlFor="password" className=" col-form-label text-muted">
                Enter New Password
              </label>
              <input
                className="form-control"
                type="Password"
                id="password"
                name="Password"
                maxLength={15}
                placeholder="Enter New Password"
                value={formPassword.Password}
                onChange={handlechange}
              />
              {formError.password && (
                <div className="text-danger text-start mx-2">
                  {" "}
                  {formError.password}{" "}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex my-2">
            <div className="col-lg-12 col-md-12 col-12 ">
              <label
                htmlFor="repassword"
                className="mt-1  col-form-label text-muted"
              >
                Re-Enter Password
              </label>
              <input
                className="form-control"
                type="Password"
                id="repassword"
                name="rePassword"
                maxLength={15}
                placeholder="Re-Enter Password"
                value={formPassword.rePassword}
                onChange={handlechange}
              />
              {formError.rePassword && (
                <div className="text-danger text-start mx-2">
                  {" "}
                  {formError.rePassword}{" "}
                </div>
              )}
              {/* {formError.rePassword && formError.password == "" && (
                <div className="text-danger text-start">
                  {" "}
                  {formError.rePassword}{" "}
                </div>
              )} */}
            </div>
            <div className="col-lg-1 col-md-0 col-sm-1 mt-4 pt-3 col-1 mx-2 d-flex justify-content-center align-items-center">
              <span>
                <img
                  src={require("../../assets/img/check.png")}
                  id="resetPasswordspan"
                  alt=""
                  className="d-none"
                ></img>
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center my-4 pt-4 col-lg-12 col-md-12 col-12">
            <button
              className="w-100 btn btn-primary"
              id="reset"
              onClick={handleResetPassword}
              // onKeyDown={handleKeyPress}
            >
              Reset Password
            </button>
            <ConfirmRePasswordModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              yesconfirm={() => handleConfirmation(true)}
            />
          </div>
        </form>
      </div>
    </LandingPageDesign>
  );
}
