export const validation = {
  onlyLettersAndSpaces,
  isValidEmail,
  passWord,
  isNotaNumber,
  isSpace,
  onlyNumeric,
  onlyLettersAndSpacialCharacters,
};
function onlyLettersAndSpaces(str: string): boolean {
  return /^[A-Za-z\s]*$/.test(str);
}
function onlyLettersAndSpacialCharacters(str: string): boolean {
  return /^[A-Za-z@~`!@#$%^&*()_=+{}[|';:\]"\\/?>.<,-\s]*$/.test(str);
}
function isSpace(str: string) {
  try {
    let value = str;
    value = value.replace(/\s/g, "");
    return value;
  } catch (error) {
    return error;
  }
}
function isValidEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}
function passWord(data: string) {
  try {
    let numvalue = data;
    numvalue = numvalue.replace(
      /[^A-Za-z0-9!@#$%^&(){}[:;<>,.?~_+-=\\/|]/g,
      ""
    );
    return numvalue;
  } catch (error) {
    return error;
  }
}
function isNotaNumber(str: string): boolean {
  return /[^\d]/.test(str);
}
function onlyNumeric(numdata: string) {
  try {
    let numvalue = numdata;
    numvalue = numvalue.replace(/[^0-9]/, "");
    return numvalue;
  } catch (error) {
    return error;
  }
}
export function onlyAlphabets(data: any) {
  try {
    let value = data;
    value = value.replace(/[^a-z\s]/gi, "");
    return value;
  } catch (error) {
    return error;
  }
}
export function alphaNumeric(data: any) {
  try {
    let numvalue = data;
    numvalue = numvalue.replace(/[^A-Za-z0-9]/g, "");
    return numvalue;
  } catch (error) {
    return error;
  }
}
export function isValidImage(FileName: any) {
  return /\.(png|jpeg|jpg)$/i.test(FileName);
}

export function isValidDocument(FileName: any) {
  return /\.(doc|docx|pdf)$/i.test(FileName);
}

export function bytesToMB(size: any) {
  return parseFloat((size / 1024 / 1024).toFixed(2));
}
